import { generatePath, Redirect, useParams } from 'react-router-dom';
import { PATH_COMPASS_PLUS, PATH_COMPASS_PLUS_RESET_PASSWORD } from '../Router';

const RedirectToCompassPlus = () => {
  const { route, extra } = useParams();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  const logoutParam = searchParams.get('logout');

  if (null != logoutParam) {
    return (
      <Redirect to={PATH_COMPASS_PLUS + '/?logout'} />
    );
  }

  const resetParam = searchParams.get('reset');

  if (null != resetParam) {
    return (
      <Redirect to={`${PATH_COMPASS_PLUS}/?reset${'' !== resetParam ? `=${resetParam}` : ''}`} />
    );
  }

  const resetPasswordSuccessParam = searchParams.get('reset-password-success');

  if (null != resetPasswordSuccessParam) {
    return (
      <Redirect to={PATH_COMPASS_PLUS + '/?reset-password-success'} />
    );
  }

  if ('reset-password' === route) {
    return (
      <Redirect to={generatePath(PATH_COMPASS_PLUS_RESET_PASSWORD, {code: extra})} />
    );
  }

  return (
    <Redirect to={PATH_COMPASS_PLUS} />
  );
};


export default RedirectToCompassPlus;
