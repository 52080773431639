import {
  AWS_ERROR_DISABLED_USER,
  AWS_ERROR_INCORRECT_CREDENTIALS,
  AWS_ERROR_PASSWORD_EXPIRED,
  AWS_ERROR_RESET_REQUIRED,
  AWS_ERROR_SESSION_EXPIRED,
  AWS_ERROR_USER_DOES_NOT_EXIST,
  DEFAULT_ERROR,
  LOGIN_ERROR_UNAUTHORIZED,
} from '../constants';
import { isString } from 'formik';

const Error = ({ message, isAws = false, resetPasswordOnClick = null }) => {
  const basicError = (errorMessage) => {
    switch (errorMessage) {
      case LOGIN_ERROR_UNAUTHORIZED:
        return 'Your user is not authorized to use this application.';
      case null:
        return DEFAULT_ERROR;
      default:
        return errorMessage;
    }
  };

  const awsError = (awsErrorMessage) => {
    switch (awsErrorMessage) {
      case AWS_ERROR_INCORRECT_CREDENTIALS:
      case AWS_ERROR_USER_DOES_NOT_EXIST:
            return (
          <>
            The credentials you entered did not match our records. Please double-check and try again. Forgotten
            your credentials? Reset these <a href="#" className="link-red-to-gray" onClick={(event) => {
            event.preventDefault();

            if (null != resetPasswordOnClick) {
              resetPasswordOnClick();
            }
          }}>here</a>.
          </>
        );
      case AWS_ERROR_DISABLED_USER:
        return (
          <>
            Your account needs to be validated before you can login. Please contact the Administrator for your school or college, or <a href="mailto:compassplus@careersandenterprise.atlassian.net" className="link-red-to-gray">contact us</a> if you do not know your Administrator.
          </>
        );
      case AWS_ERROR_PASSWORD_EXPIRED:
        return (
          <>
            Your password has expired. Please change your password <a href="#" className="link-red-to-gray" onClick={(event) => {
            event.preventDefault();

            if (null != resetPasswordOnClick) {
              resetPasswordOnClick();
            }
          }}>here</a>.
          </>
        );
      case AWS_ERROR_RESET_REQUIRED:
        return (
            <>
              Apologies, you need to reset your password. This will only take a minute - <a href="#" className="link-red-to-gray" onClick={(event) => {
              event.preventDefault();

              if (null != resetPasswordOnClick) {
                resetPasswordOnClick();
              }
            }}>please reset your password here</a>.
            </>
        );
      case AWS_ERROR_SESSION_EXPIRED:
        return (
          <>
            The session has expired. Please try again.
          </>
        );
      default:
        return awsErrorMessage;
    }
  };

  if (null == message) {
    return null;
  }

  const error = !isAws ? basicError(message) : awsError(message);

  if (isString(error)) {
    return (
      <div
        className="alert alert-cec alert-cec-warning text-left fade-in-bottom"
        dangerouslySetInnerHTML={{ __html: error }}
      />
    );
  }

  return (
    <div
      className="alert alert-cec alert-cec-warning text-left fade-in-bottom"
    >
      {error}
    </div>
  );
};

export default Error;
