import React from 'react';
import moment from 'moment';
import cecLogo from '../../images/cec-logo.svg';
import logoCompass from '../../images/schoolshub/logo-compass.svg';
import logoTracker from '../../images/schoolshub/logo-tracker.svg';

const Footer = () => {

  return (
    <footer className="container default-footer">
      <div className="hub-footer">
        <div className="container">
          <div className="row">
            <div className="col-6 txtr">
              <img src={logoCompass} width="180" height="45" alt="Compass" />
            </div>
            <div className="col-6">
              <img src={logoTracker} width="180" height="45" alt="Tracker" />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <a href={process.env.REACT_APP_CEC_WEB_URL} target="_blank" rel="noopener noreferrer">
            <img
              width="100"
              src={cecLogo}
              alt="The Careers and Enterprise Company"
              title="The Careers and Enterprise Company"
            />
          </a>
        </div>
        <div className="col-8 small">
          © The Careers and Enterprise Company {moment().format('Y')}.
          All rights reserved. <a href={`${process.env.REACT_APP_CEC_WEB_URL}/privacy-notice`} target="_blank" rel="noopener noreferrer">Privacy Policy</a> |{' '}
          <a href={`${process.env.REACT_APP_CEC_WEB_URL}/careers-leaders/tools-resources/terms-and-conditions`} target="_blank" rel="noopener noreferrer">Terms and Conditions</a> |{' '}
          <a href="mailto:help@careersandenterprise.co.uk" target="_blank" rel="noopener noreferrer">Contact Us</a>.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
