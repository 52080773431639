import React from 'react';
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => (
  <div className="d-flex w-100 h-100 mx-auto flex-column">
    {/* Header */}
    <Header />
    {/* Content */}
    <div id="eanr-content" className="container my-5 px-0">
      {/* Page Content */}
      <div className="login-content">
        <section>
          <div className="ean-login-container">
            {children}
          </div>
        </section>
      </div>
    </div>
    {/* Footer */}
    <Footer />
  </div>
);

export default Layout;
