import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from "../../components/eanr/Layout";
import SetupFormLayout from "../../components/eanr/SetupFormLayout";
import TextInput from '../../components/TextInput';
import {
  AWS_ERROR_DISABLED_USER,
  AWS_ERROR_USER_DOES_NOT_EXIST,
  FAILURE,
  LOADING,
  RESET_PASSWORD_VALIDATION_SCHEMA,
} from '../../constants';
import { PATH_EAN_REGISTER_RESET_PASSWORD_SUCCESS } from '../../Router';

const TITLE = 'EANR - Reset password';

const awsError = (awsErrorMessage) => {
  switch (awsErrorMessage) {
    case AWS_ERROR_DISABLED_USER:
      return (
        <>
          Your account needs to be validated before you can reset your password. Please contact the Administrator for your school or college, or <a href="mailto:compassplus@careersandenterprise.atlassian.net" className="link-red-to-gray">contact us</a> if you do not know your Administrator.
        </>
      );
    case AWS_ERROR_USER_DOES_NOT_EXIST:
      return (
        <>
          Your email is not registered. Please contact the Administrator for your school or college, or <a href="mailto:compassplus@careersandenterprise.atlassian.net" className="link-red-to-gray">contact us</a> if you do not know your Administrator.
        </>
      );
    default:
      return awsErrorMessage;
  }
};

const ResetPassword = () => {
  const { code } = useParams();
  const history = useHistory();
  const [ callState, setCallState ] = useState(null);
  const [ awsErrorMessage, setAwsErrorMessage ] = useState();

  useEffect(() => {
    document.title = TITLE;

    return () => {
      cleanAll();
    };
  }, []);

  const cleanAll = () => {
    cleanErrors();

    setCallState(null);
  };

  const cleanErrors = () => {
    setAwsErrorMessage(null);
  };

  return (
    <Layout>
      <SetupFormLayout>
        <Formik
          validationSchema={RESET_PASSWORD_VALIDATION_SCHEMA}
          initialValues={{
            email: '',
            password: '',
            repeatPassword: '',
            code: code ?? '',
          }}
          onSubmit={(values) => {
            cleanErrors();
            setCallState(LOADING);

            Auth.forgotPasswordSubmit(values.email, values.code, values.password)
              .then(() => {
                history.push(PATH_EAN_REGISTER_RESET_PASSWORD_SUCCESS);
              })
              .catch((error) => {
                setCallState(FAILURE);
                setAwsErrorMessage(error.message);
              });
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ getFieldProps, errors, handleSubmit, initialValues }) => (
            <form
              onSubmit={handleSubmit}
            >
              <div className="default-form text-left">
                <div className="form-group row">
                  <div className="form-block col-12">
                    <TextInput
                      {...getFieldProps('email')}
                      id="email"
                      label="Confirm your email address"
                      placeholder="Email address"
                      autoComplete="email"
                      required
                      error={errors.email}
                    />
                    <i className="mdi mdi-email-outline input-icon" />
                  </div>
                </div>
              </div>
              <div className="default-form text-left">
                <div className="form-group row">
                  <div className="form-block col-12">
                    <TextInput
                      {...getFieldProps('password')}
                      id="password"
                      label="Enter your new password"
                      placeholder="Password"
                      help="Your password must be 10 characters and include numbers, punctuation marks, capital letters and lowercase letters."
                      error={errors.password}
                      type="password"
                      required
                    />
                    <i className="mdi mdi-lock-outline input-icon" />
                  </div>
                </div>
              </div>
              <div className="default-form text-left">
                <div className="form-group row">
                  <div className="form-block col-12">
                    <TextInput
                      {...getFieldProps('repeatPassword')}
                      id="repeat-password"
                      label="Enter your password one more time"
                      placeholder="Repeat password"
                      error={errors.repeatPassword}
                      type="password"
                      required
                    />
                    <i className="mdi mdi-lock-outline input-icon" />
                  </div>
                </div>
              </div>
              <div className="default-form text-left">
                <div className="form-group row">
                  <div className="form-block col-12">
                    <TextInput
                      {...getFieldProps('code')}
                      id="code"
                      label="Verification Code"
                      placeholder="Code"
                      help="You should have received this via email"
                      error={errors.code}
                      required
                      disabled={null != initialValues.code && '' !== initialValues.code}
                    />
                    <i className="mdi mdi-lock-outline input-icon" />
                  </div>
                </div>
              </div>
              <div className="form-footer">
                <div className="remember-me">
                  <div className="form-group">
                    <label className="float-label text-left" htmlFor="permission-data">
                      We will always take appropriate measures to keep your personal information confidential, secure, and protected, including when we need to share it with third parties.
                    </label>
                  </div>
                </div>
              </div>
              {awsErrorMessage && (
                <div className="alert alert-cec alert-cec-warning text-left fade-in-bottom">
                  {awsError(awsErrorMessage)}
                </div>
              )}
              <button
                type="submit"
                className="cec-btn cec-btn-primary-teal w-100 mt-3"
                disabled={LOADING === callState}
              >
                {LOADING !== callState ? 'Reset password' : 'Resetting...'}
              </button>
            </form>
          )}
        </Formik>
      </SetupFormLayout>
    </Layout>
  );
};

export default ResetPassword;
