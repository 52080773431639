import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import TextInput from "../TextInput";
import {ErrorMessage, InfoMessage, SubmitButton} from "../Forms";
import VerifyPhoneNumberModal from "../VerifyPhoneNumberModal";
import {FAILURE, LOADING, PHONE_NUMBER_SETUP_VALIDATION_SCHEMA} from "../../constants";

import * as api from '../../api';

const INITIAL_VALUES = {
  email: '',
  phoneNumber: '',
  repeatPhoneNumber: '',
};

const PhoneNumberSetupForm = ({ pathParams, baseURL, redirectTo, isSchoolshub }) => {
  const [callState, setCallState] = useState(null);
  const [awsErrorMessage, setAwsErrorMessage] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [authCode, setAuthCode] = useState(undefined);

  const cleanErrors = () => {
    setAwsErrorMessage(null);
  };

  useEffect(() => {
    if (authCode !== undefined) {
      setModalIsOpen(true);
    }
  }, [authCode]);

  return (
    <React.Fragment>

      <VerifyPhoneNumberModal
        isOpen={modalIsOpen}
        authCode={authCode}
        baseURL={baseURL}
        redirectTo={redirectTo}
      />

      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={PHONE_NUMBER_SETUP_VALIDATION_SCHEMA}
        onSubmit={(values) => {
          cleanErrors();
          setCallState(LOADING);

          api.postEnableSMSAuthentication(
            pathParams,
            values.email,
            values.phoneNumber
          )
            .then((response) => {
              setAuthCode(response.data.auth_code);
            })
            .catch((error) => {
              setCallState(FAILURE);
              setAwsErrorMessage(error.message);
            });
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ getFieldProps, errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="default-form text-left">
              <div className="form-group row">
                <div className="form-block col-12">
                  <TextInput
                    {...getFieldProps('email')}
                    id="email"
                    label="Confirm your email address"
                    placeholder="Email address"
                    autoComplete="email"
                    required
                    error={errors.email}
                  />

                  {!isSchoolshub && (<i className="mdi mdi-email-outline input-icon" />)}
                </div>
              </div>
            </div>
            <div className="default-form text-left">
              <div className="form-group row">
                <div className="form-block col-12">
                  <TextInput
                    {...getFieldProps('phoneNumber')}
                    id="phoneNumber"
                    type="tel"
                    label="Enter your phone number"
                    placeholder="For example: +441234567"
                    required
                    error={errors.phoneNumber}
                  />

                  {!isSchoolshub && (<i className="mdi mdi-cellphone input-icon" />)}
                </div>
              </div>
            </div>
            <div className="default-form text-left">
              <div className="form-group row">
                <div className="form-block col-12">
                  <TextInput
                    {...getFieldProps('repeatPhoneNumber')}
                    id="repeatPhoneNumber"
                    type="tel"
                    label="Enter your phone number one more time"
                    placeholder="For example: +441234567"
                    required
                    error={errors.repeatPhoneNumber}
                  />

                  {!isSchoolshub && (<i className="mdi mdi-cellphone input-icon" />)}
                </div>
              </div>
            </div>
            <InfoMessage>
              We will send a text message to your phone in order to verify that the number is yours.
            </InfoMessage>
            {awsErrorMessage && (
              <ErrorMessage>
                {awsErrorMessage}
              </ErrorMessage>
            )}
            <SubmitButton isSchoolshub={isSchoolshub}>
              {LOADING !== callState ? 'Set up' : 'Setting up...'}
            </SubmitButton>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default PhoneNumberSetupForm;
