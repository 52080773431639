import { MFA_TYPE } from '../constants';

// Overrides the sendMFASelectionAnswer function from amazon-cognito-identity-js CognitoUser class
// to add the clientMetadata parameter to the request
export function sendMFASelectionAnswer(answerChallenge, callback, cognitoUser, clientMetadata){
    const challengeResponses = {};
    challengeResponses.USERNAME = cognitoUser.username;
    challengeResponses.ANSWER = answerChallenge;

    const jsonReq = {
        ChallengeName: MFA_TYPE.SELECT,
        ChallengeResponses: challengeResponses,
        ClientId: cognitoUser.pool.getClientId(),
        Session: cognitoUser.Session,
        ClientMetadata: clientMetadata,
    };
    if (cognitoUser.getUserContextData()) {
        jsonReq.UserContextData = cognitoUser.getUserContextData();
    }
    cognitoUser.client.request('RespondToAuthChallenge', jsonReq, (err, data) => {
        if (err) {
            return callback.onFailure(err);
        }
        cognitoUser.Session = data.Session;
        if (answerChallenge === MFA_TYPE.SMS) {
            return callback.mfaRequired(
                data.ChallengeName,
                data.ChallengeParameters
            );
        }
        if (answerChallenge === MFA_TYPE.APP) {
            return callback.totpRequired(
                data.ChallengeName,
                data.ChallengeParameters
            );
        }
        return undefined;
    });
}
