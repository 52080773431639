import React, {useEffect} from 'react';
import Layout from "../../components/eanr/Layout";
import SetupFormLayout from "../../components/eanr/SetupFormLayout";
import PhoneNumberSetupForm from "../../components/mfa/PhoneNumberSetupForm";
import {useHistory, useParams} from "react-router-dom";
import {PATH_EAN_REGISTER, PATH_EAN_REGISTER_TEXT_MESSAGE_SETUP_SUCCESS} from "../../Router";
import {EANR_URL} from "../../api";

const TITLE = 'EANR - Phone number setup';

const PhoneNumberSetup = () => {

  const { params } = useParams();

  const history = useHistory();

  useEffect(() => {
    document.title = TITLE;

    if (!params) {
      history.push(PATH_EAN_REGISTER);
    }

  }, [params]);

  if (!params) {
    return <></>;
  }

  return (
    <Layout>
      <SetupFormLayout>
        <PhoneNumberSetupForm
          pathParams={params}
          baseURL={EANR_URL}
          redirectTo={PATH_EAN_REGISTER_TEXT_MESSAGE_SETUP_SUCCESS}
        />
      </SetupFormLayout>
    </Layout>
  )
}

export default PhoneNumberSetup;
