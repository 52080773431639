import cecLogo from '../../images/cec-logo.svg';

const Footer = () => {
  return (
    <footer id="eanr-footer" className="bg-light border-top mt-auto">
      <div className="container py-3">
        <a href="#" className="logo">
          <img src={cecLogo} alt="The Careers & Enterprise Company" title="The Careers & Enterprise Company" />
        </a>
        <div className="buttons">
          <p className="mb-0">
            © The Careers and Enterprise Company {(new Date().getFullYear())}. All rights reserved. <a href="https://www.careersandenterprise.co.uk/privacy-notice/" className="link-teal-to-gray-color-blind" target="_blank" rel="noopener noreferrer">CEC privacy notice</a>.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
