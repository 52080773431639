import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import Layout from '../../components/eanr/Layout';
import Error from '../../components/Error';
import TextInput from '../../components/TextInput';
import ForgottenPasswordModal from '../../components/ForgottenPasswordModal';
import AskMFAModal from '../../components/mfa/modals/AskMFAModal';
import { PATH_EAN_REGISTER } from '../../Router';
import { APP_EAN_REGISTER, LOGIN_ERROR_UNAUTHORIZED, MFA_TYPE } from '../../constants';

import * as api from '../../api';

import cecLogo from '../../images/cec-logo.svg';
import { sendMFASelectionAnswer } from '../../components/Utils';

const TITLE = 'EANR - Log in';
const COGNITO_CLIENT_METADATA = { app: APP_EAN_REGISTER };
const PASSWORD_RESET_EMAIL_CLIENT_METADATA = { ...COGNITO_CLIENT_METADATA, action: 'resetPassword' }

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
  password: Yup.string()
    .required('Please enter a password.'),
});

const Login = () => {
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();
  const [ awsErrorMessage, setAwsErrorMessage ] = useState();
  const [ isOpenForgottenPasswordModal, setIsOpenForgottenPasswordModal ] = useState(false);
  const [ forgottenPasswordEmail, setForgottenPasswordEmail ] = useState();
  const [ successMessage, setSuccessMessage ] = useState(null);

  const [ isMfaModalOpen, setMfaModalOpen ] = useState(false);
  const [ cognitoUser, setCognitoUser ] = useState(null);
  const [ userCredentials, setUserCredentials ] = useState([]);

  const handleLogin = (forcedCognitoUser = null) => {
    const {
      idToken,
      refreshToken,
      accessToken,
    } = (forcedCognitoUser ?? cognitoUser).signInUserSession;

    api.loginEANR(idToken.jwtToken, refreshToken.token, accessToken.jwtToken)
        .then(() => window.location = process.env.REACT_APP_EANR_URL)
        .catch((error) => {
          setErrorMessage(error.response?.data?.error ?? error.response?.data?.detail ?? ((401 === error.response?.status) ? LOGIN_ERROR_UNAUTHORIZED : null));
          setIsLoggingIn(false);
          setMfaModalOpen(false);
        });
  }

  useEffect(() => {
    document.title = TITLE;

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const logoutParam = searchParams.get('logout');

    if (null != logoutParam) {
      window.history.replaceState({}, document.title, PATH_EAN_REGISTER);

      Auth.signOut()
        .then(() => {
        });

      return;
    }

    const resetParam = searchParams.get('reset');

    if (null != resetParam) {
      window.history.replaceState({}, document.title, PATH_EAN_REGISTER);

      setIsOpenForgottenPasswordModal(true);
      setForgottenPasswordEmail(resetParam);

      return;
    }

    const resetPasswordSuccessParam = searchParams.get('reset-password-success');
    const phoneNumberSetupParam = searchParams.get('phone-number-setup-success');

    if (null != resetPasswordSuccessParam) {
      setSuccessMessage('Your password has been reset.');
    }

    if (null !== phoneNumberSetupParam) {
      setSuccessMessage('Your MFA has been updated.');
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanAll();
    };
  }, []);

  const cleanAll = () => {
    cleanErrors();

    setIsLoggingIn(false);
  };

  const cleanErrors = () => {
    setAwsErrorMessage(null);
    setErrorMessage(null);
  };

  const showMFAModal = (values) => {
    setUserCredentials([values.email, values.password]);
    setMfaModalOpen(true);
  }

  return (
    <>
      <Layout>
        <div className="ean-login-text">
          <img src={cecLogo} alt="The Careers & Enterprise Company" title="The Careers & Enterprise Company" />
          <div className="text-muted">
            <p>
              Welcome to the Enterprise Adviser network register.
            </p>
            <p className="mb-0">
              Please enter your email address and password and click log in.
            </p>
          </div>
        </div>
        {successMessage && (
          <div className="alert alert-success mb-5">
            {successMessage}
          </div>
        )}
        <div className="ean-login-form">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(values) => {
              setIsLoggingIn(true);
              cleanErrors();

              Auth.signIn(values.email, values.password, COGNITO_CLIENT_METADATA)
                .then((cognitoResponse) => {
                  const type = cognitoResponse.challengeName;

                  if ([MFA_TYPE.APP, MFA_TYPE.EMAIL, MFA_TYPE.SMS].includes(type)) {
                    setCognitoUser(cognitoResponse);

                    showMFAModal(values);

                    return;
                  }

                  if ( MFA_TYPE.SELECT === type ) {
                    cognitoResponse.challengeName = MFA_TYPE.SMS;
                    setCognitoUser(cognitoResponse);
                    sendMFASelectionAnswer(MFA_TYPE.SMS, {
                      mfaRequired: (challengeName, challengeParameters) => {
                        showMFAModal(values);
                      },
                      totpRequired: (challengeName, challengeParameters) => {
                        showMFAModal(values);
                      }
                    }, cognitoResponse, COGNITO_CLIENT_METADATA);

                    return;
                  }

                  setCognitoUser(cognitoResponse);

                  handleLogin(cognitoResponse);
                })
                .catch((error) => {
                  setAwsErrorMessage(error.message);
                  setIsLoggingIn(false);
                });
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, getFieldProps, errors }) => (
              <form
                onSubmit={handleSubmit}
              >
                {/* Email Address */}
                <div className="form-group">
                  <div className="default-form form-group position-relative">
                    <div className="form-block">
                      <TextInput
                        {...getFieldProps('email')}
                        id="email"
                        label="Email Address"
                        placeholder="Please enter your email address"
                        autoComplete="off"
                        required
                        error={errors.email}
                      />
                      <i className="mdi mdi-at input-icon" />
                    </div>
                  </div>
                </div>
                {/* Password */}
                <div className="form-group">
                  <div className="default-form form-group position-relative">
                    <div className="form-block">
                      <TextInput
                        {...getFieldProps('password')}
                        id="password"
                        label="Password"
                        placeholder="Please enter your password"
                        autoComplete="off"
                        required
                        error={errors.password}
                        type="password"
                      />
                      <i className="mdi mdi-lock input-icon" />
                    </div>
                  </div>
                </div>
                {/* Forgot - Don't include Remember me checkbox */}
                <div className="form-footer">
                  <div className="forgotten">
                    <a
                      href="#"
                      className="link-teal-to-gray-color-blind font-14"
                      onClick={(event) => {
                        event.preventDefault();

                        setIsOpenForgottenPasswordModal(true);
                      }}
                    >
                      I have forgotten my credentials
                    </a>
                  </div>
                </div>

                {/* Message Errors */}
                <Error
                  message={errorMessage ?? awsErrorMessage}
                  isAws={null == errorMessage && null != awsErrorMessage}
                  resetPasswordOnClick={() => setIsOpenForgottenPasswordModal(true)}
                />

                {/* Log In */}
                <div className="d-flex mt-5">
                  <button
                    type="submit"
                    className="btn cec-btn-primary-teal cec-btn-primary-color-blind w-50 mx-auto"
                    data-cy="login"
                    disabled={isLoggingIn}
                  >
                    {!isLoggingIn ? 'Log in' : 'Logging in...'}
                  </button>
                </div>
                <p style={{textAlign: "left", marginTop: "1rem"}} className="text-muted">
                  This website is for Authorised Users only.
                </p>
              </form>
            )}
          </Formik>
        </div>
      </Layout>

      <ForgottenPasswordModal
        email={forgottenPasswordEmail}
        isOpen={isOpenForgottenPasswordModal}
        toggle={() => setIsOpenForgottenPasswordModal((prev) => !prev)}
        cognitoClientMetadata={PASSWORD_RESET_EMAIL_CLIENT_METADATA}
      />
      {isMfaModalOpen && (
        <AskMFAModal
          project={'EANR'}
          toggle={() => setMfaModalOpen((prev) => !prev)}
          setCognitoUser={setCognitoUser}
          cognitoUser={cognitoUser}
          userCredentials={userCredentials}
          handleSuccess={() => handleLogin()}
          handleLoginAwsError={(errorMessage) => {
            setAwsErrorMessage(errorMessage);
            setIsLoggingIn(false);
            setMfaModalOpen(false);
          }}
          clientMetadata={COGNITO_CLIENT_METADATA}
        />
      )}
    </>
  );
};

export default Login;
