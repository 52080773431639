import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TextInput from '../../TextInput';
import { AWS_ERROR_SESSION_EXPIRED, MFA_TYPE, MFA_VALIDATION_SCHEMA } from '../../../constants';

import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import { sendMFASelectionAnswer } from '../../Utils';


// Time for the countdown (seconds)
const COUNTDOWN = 3 * 60;

const formatCountdown = (timeLeft) => {
  const minutesLeft = Math.floor(timeLeft / 60);
  const secondsLeft = timeLeft - (minutesLeft * 60);

  return `${minutesLeft}:${('0' + secondsLeft).slice(-2)}`;
}

const isEmailMFA = (value) => value.length > 4 && value.slice(-4) === '0000';

const AskMFAModal = (
  {
    project,
    cognitoUser,
    setCognitoUser,
    handleSuccess,
    handleLoginAwsError,
    userCredentials,
    clientMetadata,
  },
) => {
  const [ isAuthorizing, setAuthorizing ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ invalidSessionOrCodeTimeLeft, setInvalidSessionOrCodeTimeLeft ] = useState(COUNTDOWN);

  // This is needed as email and sms will have the same challenge name.
  // We need is email because the the code will be +*******0000.
  const type = cognitoUser.challengeName === MFA_TYPE.SMS ? (
    isEmailMFA(cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION) ? MFA_TYPE.EMAIL : MFA_TYPE.SMS
  ) : cognitoUser.challengeName;

  const restartCountdown = () => setInvalidSessionOrCodeTimeLeft(COUNTDOWN);

  useEffect(() => {
    if (MFA_TYPE.APP === type) {
      return;
    }

    const interval = setInterval(() => {
      setInvalidSessionOrCodeTimeLeft((prev) => {
        if (0 === prev) {
          return prev;
        }

        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [type]);

  return (
    <Modal
      className="default-modal"
      centered
      isOpen
      //toggle={} Don't use toggle to avoid leaving the modal
      size={'md'}
    >
      <ModalHeader>
        Verify your identity
      </ModalHeader>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={MFA_VALIDATION_SCHEMA}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setAuthorizing(true);

          Auth.confirmSignIn(cognitoUser, values.code, cognitoUser.challengeName, clientMetadata)
            .then(() => handleSuccess(cognitoUser))
            .catch((error) => {
              setAuthorizing(false);

              if (AWS_ERROR_SESSION_EXPIRED === error.message) {
                handleLoginAwsError(error.message);

                return;
              }

              setError(error);
            })
          ;
        }}
      >
        {({ errors, handleSubmit, setFieldValue, setFieldError, values }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <p><b>Why I'm seeing this?</b></p>
              <p>
                For security reasons, we need to verify your identity before logging in.
              </p>
              {MFA_TYPE.APP === type ? (
                <p>
                  To continue, please verify your identity using the six-digit code in your authenticator app.
                </p>
              ) : (
                <>
                  <p>
                    We have sent a six-digit code to your
                    {' '}{MFA_TYPE.EMAIL === type && 'email address'}{MFA_TYPE.SMS === type && 'phone'}.
                    {' '}Please enter it in the field below.
                  </p>
                  <p>
                    <b>It will expire in three minutes.</b>
                    <button
                      type={'button'}
                      className={`button-as-link ${0 < invalidSessionOrCodeTimeLeft ? 'disabled' : 'link-teal-to-gray'}`}
                      disabled={0 !== invalidSessionOrCodeTimeLeft}
                      onClick={() => {
                        restartCountdown();

                        Auth.signIn(...userCredentials, clientMetadata)
                          .then((cognitoUserResponse) => {
                              const type = cognitoUserResponse.challengeName;

                              if ( MFA_TYPE.SELECT === type ) {
                                  cognitoUserResponse.challengeName = MFA_TYPE.SMS;
                                  setCognitoUser(cognitoUserResponse);
                                  sendMFASelectionAnswer(MFA_TYPE.SMS, {
                                      mfaRequired: (challengeName, challengeParameters) => {
                                      },
                                      totpRequired: (challengeName, challengeParameters) => {
                                      }
                                  }, cognitoUserResponse, clientMetadata);

                                  return;
                              }

                              setCognitoUser(cognitoUserResponse);
                          });
                      }}
                    >
                      Resend code {0 !== invalidSessionOrCodeTimeLeft ? `(${formatCountdown(invalidSessionOrCodeTimeLeft)})` : ''}
                    </button>
                  </p>
                </>
              )}
              <div className="alert alert-cec">
                <div className={"mb-3"}>
                  <b>
                    Do you prefer to verify your identity {MFA_TYPE.EMAIL === type ? 'using an authenticator app or your phone' : 'with a code sent to your email address'}?
                  </b>
                  {' '}Once you have logged in, access your profile information and update your Multi-Factor Authentication settings.</div>
                {(MFA_TYPE.APP === type || MFA_TYPE.SMS === type) && (
                  <div>
                    <div>
                      <b>
                        Do you need to reset your
                        {' '}{MFA_TYPE.APP === type && 'app'}{MFA_TYPE.SMS === type && 'text message'}
                        {' '}authentication?
                      </b>
                    </div>
                    <div>
                      <a
                        className={'link-white-to-white'}
                        href={`mailto:compassplus@careersandenterprise.co.uk?Subject=Reset ${project} MFA`}
                        target={'_blank'}
                        rel={'noopener, noreferrer'}
                      >Contact us</a> and we will switch your MFA back to email verification.
                    </div>
                  </div>
                )}
              </div>
              <div className={'default-form'}>
                {/* Code */}
                <div className="form-group row">
                  <div className="form-block col-12">
                    <TextInput
                      id="mfa_code"
                      name="code"
                      placeholder="- - - - - -"
                      label="Your code"
                      error={errors.code}
                      onChange={(event) => {
                        setFieldValue('code', event.target.value);
                        setFieldError('code', null);
                        setError(null);
                      }}
                      value={values.code}
                      required
                    />
                    <i className="mdi mdi-lock-clock input-icon" />
                  </div>
                </div>
                {null != error && (
                  <div className="alert alert-danger">
                    Incorrect code. Please try again.
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="submit"
                className="cec-btn cec-btn-primary-teal"
                disabled={isAuthorizing}
              >
                {isAuthorizing ? 'Authenticating...' : 'Authenticate'}
              </button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AskMFAModal;
