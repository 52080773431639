import React, { useEffect } from 'react';
import Layout from '../../components/schoolshub/Layout';
import SetupFormLayout from '../../components/schoolshub/SetupFormLayout';
import PhoneNumberSetupForm from '../../components/mfa/PhoneNumberSetupForm';
import { useHistory, useParams } from 'react-router-dom';
import { PATH_SCHOOLSHUB, PATH_SCHOOLSHUB_TEXT_MESSAGE_SETUP_SUCCESS } from '../../Router';
import { COMPASS_CLASSIC_URL } from '../../api';

const TITLE = 'Schools Hub - Phone number setup';

const PhoneNumberSetup = () => {
  const { params } = useParams();

  const history = useHistory();

  useEffect(() => {
    document.title = TITLE;

    if (!params) {
      history.push(PATH_SCHOOLSHUB);
    }

  }, [ params ]);

  if (!params) {
    return <></>;
  }

  return (
    <Layout>
      <SetupFormLayout>
        <PhoneNumberSetupForm
          pathParams={params}
          baseURL={COMPASS_CLASSIC_URL}
          redirectTo={PATH_SCHOOLSHUB_TEXT_MESSAGE_SETUP_SUCCESS}

          isSchoolshub
        />
      </SetupFormLayout>
    </Layout>
  );
};

export default PhoneNumberSetup;
