import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import Layout from "../../components/compass_plus/Layout";
import SetupFormLayout from "../../components/compass_plus/SetupFormLayout";
import PhoneNumberSetupForm from "../../components/mfa/PhoneNumberSetupForm";
import {PATH_COMPASS_PLUS, PATH_COMPASS_PLUS_TEXT_MESSAGE_SETUP_SUCCESS} from "../../Router";
import {COMPASS_PLUS_URL} from "../../api";

const TITLE = 'Compass+ - Phone number setup';

const PhoneNumberSetup = () => {

  const { params } = useParams();

  const history = useHistory();

  useEffect(() => {
    document.title = TITLE;

    if (!params) {
      history.push(PATH_COMPASS_PLUS);
    }

  }, [params]);

  if (!params) {
    return <></>;
  }

  return (
    <Layout>
      <SetupFormLayout title="Set up phone number">
        <PhoneNumberSetupForm
          pathParams={params}
          baseURL={COMPASS_PLUS_URL}
          redirectTo={PATH_COMPASS_PLUS_TEXT_MESSAGE_SETUP_SUCCESS}
        />
      </SetupFormLayout>
    </Layout>
  )
}

export default PhoneNumberSetup;

