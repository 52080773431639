import React from 'react';
import logoCecWhite from '../../images/cec-logo-white.png';
import {Amplify} from 'aws-amplify';
import awsExports from '../../aws-exports';
import awsExportsSchoolshub from '../../aws-exports-schoolshub';
import { PATH_SCHOOLSHUB } from '../../Router';

const Header = () => {
  Amplify.configure({ ...awsExports, ...awsExportsSchoolshub });

  return (
    <>
      <nav className="top-nav">
        <div className="container">
          <div className="top-head-menu flex justify-content-between">
            <a target="_blank" href={process.env.REACT_APP_CEC_WEB_URL}>
              <img src={logoCecWhite} width="108px" alt="Compass" />
            </a>

            <div>
              <a href={PATH_SCHOOLSHUB} className="btn-transparent-round">
                Log in
              </a>
              <a href={`${process.env.REACT_APP_SCHOOLSHUB_URL}/register`} className="btn-transparent-round">
                Create an account
              </a>
            </div>
          </div>
        </div>
      </nav>

      <div className="app-header" />
    </>
  );
};

export default Header;
