import React, {useEffect, useState} from 'react';
import {CheckEmail} from "./views/CheckEmail";
import {SignUpInterstitialPage} from "./views/SignUpInsterstitialPage";

const TITLE = 'Digitalhub - Login';

export const STATE_LANDING_PAGE = 'LANDING PAGE';
export const STATE_CONFIRM_CONTINUE_TO_LOGIN = 'CONFIRM RECEIVED SIGN UP EMAIL';

const STATES_TO_COMPONENT_MAP = {
    [STATE_LANDING_PAGE]: CheckEmail,
    [STATE_CONFIRM_CONTINUE_TO_LOGIN]: SignUpInterstitialPage,
};

const PARAMS_TO_FORWARD = [
    'client_id',
    'response_type',
    'scope',
    'state',
    'redirect_uri',
];

const COGNITO_HOSTED_UI_URL_BASE = process.env.REACT_APP_COGNITO_HOSTED_UI_URL;
export const COGNITO_HOSTED_UI_LOGIN_ENDPOINT = 'login';

export const buildRedirectUrl = (requestParams, endpoint, additionalParams = {}) => {
    if (undefined === endpoint) {
        throw new Error(`No endpoint passed when building redirect URL`);
    }

    const redirectUrl = new URL(`${COGNITO_HOSTED_UI_URL_BASE}/${endpoint}`);

    PARAMS_TO_FORWARD.forEach((paramName) => requestParams.hasOwnProperty(paramName) && redirectUrl.searchParams.append(paramName, requestParams[paramName]));

    Object.entries(additionalParams).forEach(([paramName, paramValue]) => redirectUrl.searchParams.append(paramName, paramValue));

    return redirectUrl;
};

export const doRedirect = (redirectUrl) => window.location.href = redirectUrl.href;

const componentify = (Component, props) => <Component {...props} />;

const OauthLandingPage = () => {
    const [pageState, setPageState] = useState(STATE_LANDING_PAGE);
    const [requestParams, setRequestParams] = useState({});

    const redirectToCognitoLogin = () => doRedirect(
        buildRedirectUrl(
            requestParams,
            COGNITO_HOSTED_UI_LOGIN_ENDPOINT,
        )
    );

    useEffect(() => {
        document.title = TITLE;

        setRequestParams(Object.fromEntries(new URLSearchParams(window.location.search)));
    }, []);

    return componentify(
      STATES_TO_COMPONENT_MAP[pageState],
      {
          setPageState,
          redirectToCognitoLogin,
      },
  );
};

export default OauthLandingPage;
