import {OauthContent} from "../components/OauthContent";

export const SignUpInterstitialPage = ({redirectToCognitoLogin}) => {

    return <OauthContent
        descriptionContent={
            <>
                <p>
                    Thanks for setting up your Digital Hub account, we have sent you an email with a temporary password.
                </p>
            </>
        }
    >
        <button
            className="btn btn-primary submitButton-customizable"
            onClick={redirectToCognitoLogin}
        >
            CONTINUE TO LOGIN USING THE PASSWORD WE HAVE EMAILED TO YOU
        </button>
    </OauthContent>
};
