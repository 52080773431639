import axios from 'axios';

export const COMPASS_PLUS_URL = process.env.REACT_APP_COMPASS_PLUS_URL;
export const COMPASS_CLASSIC_URL = process.env.REACT_APP_COMPASS_CLASSIC_URL;
const CAREERS_PARTNERS_URL = process.env.REACT_APP_CAREERS_PARTNERS_URL;
export const EANR_URL = process.env.REACT_APP_EANR_URL;
const CLTP_API_URL = process.env.REACT_APP_CLTP_API_URL;

export function loginCompassPlus(jwtToken, refreshToken, accessToken, isCompassClassic = false) {
  const headers = {
    Authorization: 'Bearer ' + jwtToken,
  };
  if (isCompassClassic) {
    headers['X-Compass-Login'] = true;
  }
  return axios.post(
    COMPASS_PLUS_URL + '/api/login',
    {
      refreshToken: refreshToken,
      accessToken: accessToken,
    },
    {
      headers: headers,
      withCredentials: true,
    },
  );
}

export function loginCompassClassic(jwtToken, refreshToken) {
  return axios.post(
    COMPASS_CLASSIC_URL + '/api/login',
    {
      refreshToken: refreshToken,
    },
    {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
      withCredentials: true,
    },
  );
}

export function getCompassPlusOauthStatus(email) {
  return axios.get(
    `${COMPASS_PLUS_URL}/api/public/digitalhub/oauth-status?email=${encodeURIComponent(email)}`,
    {
      withCredentials: true,
    },
  );
}

export function loginCareersPartners(jwtToken, refreshToken, compassPlus = false) {
  const headers = {
    Authorization: 'Bearer ' + jwtToken,
  };

  if (compassPlus) {
    headers['X-Compass-Plus-Login'] = true;
  }

  return axios.post(
    CAREERS_PARTNERS_URL + '/api/login',
    {
      refreshToken: refreshToken,
    },
    {
      headers: headers,
      withCredentials: true,
    },
  );
}

export function loginEANR(jwtToken, refreshToken, accessToken) {
  return axios.post(
      EANR_URL + '/api/login',
      {
        refreshToken: refreshToken,
        accessToken: accessToken,
      },
      {
        headers: {
          Authorization: 'Bearer ' + jwtToken,
        },
        withCredentials: true,
      },
  );
}

export function loginCltp(jwtToken, refreshToken) {
  return axios.post(
    CLTP_API_URL + '/refresh-token',
    {
      refreshToken: refreshToken,
    },
    {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
      withCredentials: true,
    },
  );
}

export function getDigitalhubOauthStatus(email) {
  return axios.get(
      `${EANR_URL}/api/digitalhub/oauth-status?email=${encodeURIComponent(email)}`,
      {
          withCredentials: true,
      },
  );
}

export function createEnterpriseAdvisorAccount(email) {
  return axios.post(
      `${EANR_URL}/api/digitalhub/create-account`,
      {
        email,
      },
      {
          withCredentials: true,
      },
  );
}

export function postEnableSMSAuthentication(auth, email, phoneNumber) {
  return axios.post(
    `${COMPASS_PLUS_URL}/api/public/update-phone-number`,
    {
      auth_code: auth,
      email: email,
      phone_number: phoneNumber,
    },
    {
        withCredentials: true,
    },
  );
}

export function postVerifyPhoneNumber(auth, code) {
  return axios.post(
    `${COMPASS_PLUS_URL}/api/public/verify-phone-number`,
    {
      auth_code: auth,
      code: code,
    },
    {
        withCredentials: true,
    },
  );
}
