import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompassPlusResetPassword from './pages/compass_plus/ResetPassword';
import EANRegisterResetPassword from './pages/eanr/ResetPassword';
import CompassPlusLogin from './pages/compass_plus/Login';
import EANRegisterLogin from './pages/eanr/Login';
import CompassPlusPhoneNumberSetup from './pages/compass_plus/PhoneNumberSetup';
import EANRegisterPhoneNumberSetup from './pages/eanr/PhoneNumberSetup';
import SchoolshubPhoneNumberSetup from './pages/schoolshub/PhoneNumberSetup';
import CLTPLogin from './pages/cltp/Login';
import SchoolshubLogin from './pages/schoolshub/Login';
import SchoolshubResetPassword from './pages/schoolshub/ResetPassword';
import DigitalhubOauthLandingPage from './pages/digitalhub/OauthLandingPage';
import RedirectToCompassPlus from './components/RedirectToCompassPlus';
import Impersonate from './pages/impersonate/Impersonate';

const ImportGlobalStyles = React.lazy(() => import('./ImportCompassPlusStyles'));
const ImportDigitalhubStyles = React.lazy(() => import('./ImportDigitalhubStyles'));
const ImportSchoolshubStyles = React.lazy(() => import('./ImportSchoolshubStyles'));

export const PATH_BASE = '/:route?/:extra?';

export const PATH_COMPASS_PLUS = '/compassplus';
export const PATH_COMPASS_PLUS_RESET_PASSWORD_SUCCESS = PATH_COMPASS_PLUS + '/?reset-password-success';
export const PATH_COMPASS_PLUS_RESET_PASSWORD = PATH_COMPASS_PLUS + '/reset-password/:code?';
export const PATH_COMPASS_PLUS_TEXT_MESSAGE_SETUP_SUCCESS = PATH_COMPASS_PLUS + '/?phone-number-setup-success';
export const PATH_COMPASS_PLUS_TEXT_MESSAGE_SETUP = PATH_COMPASS_PLUS + '/phone-number-setup/:params';
export const PATH_COMPASS_PLUS_IMPERSONATE_USER = PATH_COMPASS_PLUS + '/impersonate/:email/:emailToImpersonate';

export const PATH_EAN_REGISTER = '/eanr';
export const PATH_EAN_REGISTER_RESET_PASSWORD_SUCCESS = PATH_EAN_REGISTER + '/?reset-password-success';
export const PATH_EAN_REGISTER_RESET_PASSWORD = PATH_EAN_REGISTER + '/reset-password/:code?';
export const PATH_EAN_REGISTER_TEXT_MESSAGE_SETUP_SUCCESS = PATH_EAN_REGISTER + '/?phone-number-setup-success';
export const PATH_EAN_REGISTER_TEXT_MESSAGE_SETUP = PATH_EAN_REGISTER + '/phone-number-setup/:params';

export const PATH_CLTP = '/cltp';

export const PATH_SCHOOLSHUB = '/schoolshub';
export const PATH_SCHOOLSHUB_RESET_PASSWORD_SUCCESS = PATH_SCHOOLSHUB + '/?reset-password-success';
export const PATH_SCHOOLSHUB_RESET_PASSWORD = PATH_SCHOOLSHUB + '/reset-password/:code?';
export const PATH_SCHOOLSHUB_TEXT_MESSAGE_SETUP_SUCCESS = PATH_SCHOOLSHUB + '/?phone-number-setup-success';
export const PATH_SCHOOLSHUB_TEXT_MESSAGE_SETUP = PATH_SCHOOLSHUB + '/phone-number-setup/:params';

export const PATH_DIGITALHUB_OAUTH = '/digitalhub/oauth';

const Router = () => {
  return (
    <React.Suspense fallback={<></>}>

      <Switch>
        {/* Digital Hub (and don't load global CSS)  */}
        <Route path={PATH_DIGITALHUB_OAUTH} exact>
          <ImportDigitalhubStyles />
          <DigitalhubOauthLandingPage />
        </Route>

        {/* Load CSS for everything else */}
        <Route path="/">
          <ImportGlobalStyles />
          <Switch>
            {/* Compass+ */}
            <Route path={PATH_COMPASS_PLUS_TEXT_MESSAGE_SETUP} exact>
              <CompassPlusPhoneNumberSetup />
            </Route>
            <Route path={PATH_COMPASS_PLUS_RESET_PASSWORD} exact>
              <CompassPlusResetPassword />
            </Route>
            <Route path={PATH_COMPASS_PLUS} exact>
              <CompassPlusLogin />
            </Route>

            {/* EAN-Register */}
            <Route path={PATH_EAN_REGISTER_TEXT_MESSAGE_SETUP} exact>
              <EANRegisterPhoneNumberSetup />
            </Route>
            <Route path={PATH_EAN_REGISTER_RESET_PASSWORD} exact>
              <EANRegisterResetPassword />
            </Route>
            <Route path={PATH_EAN_REGISTER} exact>
              <EANRegisterLogin />
            </Route>

            {/* CLTP */}
            <Route path={PATH_CLTP} exact>
              <CLTPLogin />
            </Route>

            {/* Schoolshub */}
            <Route path={PATH_SCHOOLSHUB_TEXT_MESSAGE_SETUP} exact>
              <ImportSchoolshubStyles />
              <SchoolshubPhoneNumberSetup />
            </Route>
            <Route path={PATH_SCHOOLSHUB_RESET_PASSWORD} exact>
              <ImportSchoolshubStyles />
              <SchoolshubResetPassword />
            </Route>
            <Route path={PATH_SCHOOLSHUB} exact>
              <ImportSchoolshubStyles />
              <SchoolshubLogin />
            </Route>

            <Route path={PATH_COMPASS_PLUS_IMPERSONATE_USER} exact>
              <ImportDigitalhubStyles />
              <Impersonate />
            </Route>

            <Route path={PATH_BASE}>
              <RedirectToCompassPlus />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </React.Suspense>
  );
};

export default Router;
