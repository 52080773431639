import {Formik} from "formik";
import {
    createEnterpriseAdvisorAccount,
    getCompassPlusOauthStatus,
    getDigitalhubOauthStatus
} from "../../../api";
import TextInput from "../../../components/TextInput";
import {OauthContent} from "../components/OauthContent";
import * as Yup from "yup";
import {useState} from "react";
import {
    STATE_CONFIRM_CONTINUE_TO_LOGIN
} from "../OauthLandingPage";

const NOT_ALLOWED_STATUS = 'NOT ALLOWED';
const ALLOWED_HAS_ACCOUNT_STATUS = 'ALLOWED HAS ACCOUNT';
const ALLOWED_NEEDS_ACCOUNT_STATUS = 'ALLOWED NEEDS ACCOUNT';

const NOT_ALLOWED_ERROR_MESSAGE = 'Sorry, we do not recognise that email.  Please use the same email that you provided on your Memorandum of Understanding.';
const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong. Please try again later.';

const VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.'),
});

const OAUTH_API_CALLS = [
    getDigitalhubOauthStatus,
    getCompassPlusOauthStatus
]

export const CheckEmail = ({setPageState, redirectToCognitoLogin}) => {
    const [isLoading, setIsLoading] = useState(false);

    const getOauthApiCalls = email => {
        let oauthApiCalls = [];
        for (const call of OAUTH_API_CALLS) {
            oauthApiCalls.push(call(email).catch((error) => error));
        }

        return oauthApiCalls;
    }

    return <OauthContent
        descriptionContent={
            <>
                <p>Please enter your email.</p>
                <div>
                    <div className={'mb-2'}>
                        Important
                    </div>
                    <span className={'font-weight-normal'}>
                        <ul>
                            <li>Hub Leads and Enterprise Co-ordinators, use your EANR email</li>
                            <li>Enterprise Advisers, use the email you use with your Enterprise Co-ordinator</li>
                        </ul>
                    </span>
                </div>
                <p>
                    You will then be asked to either login or sign up using the same email address.
                </p>
            </>
        }
    >
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(values, {setFieldError}) => {
                setIsLoading(true);

                const {email} = values;
                const handleError = () => {
                    setFieldError('email', SOMETHING_WENT_WRONG_MESSAGE);
                    setIsLoading(false);
                };

                Promise.all(getOauthApiCalls(email)).then(responses => {
                    responses = responses.map(response => {
                        if (response.data && response.data.status) {
                            return response.data.status;
                        }

                        return response;
                    });

                    if (responses.includes(ALLOWED_HAS_ACCOUNT_STATUS)){
                        redirectToCognitoLogin();

                        return;
                    }

                    if (responses.includes(ALLOWED_NEEDS_ACCOUNT_STATUS)) {
                        createEnterpriseAdvisorAccount(email)
                          .then((response) => {
                              if (200 !== response.status) {
                                  handleError();
                              }

                              setPageState(STATE_CONFIRM_CONTINUE_TO_LOGIN);
                          })
                          .catch((error) => {
                              console.error(error);
                              handleError();
                          });

                        return;
                    }

                    if (responses.includes(NOT_ALLOWED_STATUS)) {
                        setFieldError('email', NOT_ALLOWED_ERROR_MESSAGE);
                        setIsLoading(false);

                        return;
                    }

                    handleError();
                });
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ getFieldProps, errors, handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                >
                    {/*Email */}
                    <TextInput
                        {...getFieldProps('email')}
                        className="form-control inputField-customizable"
                        id="email"
                        label="Email"
                        autoComplete="email"
                        placeholder="Email address"
                        required
                        error={errors.email}
                        labelClass={'label-customizable'}
                        ErrorComponent={
                            ({error}) => <>
                                {
                                    null != error
                                        ? <div className="form-error mt-2 errorMessage-customizable">
                                            {error}
                                        </div>
                                        : null
                                }
                            </>
                        }
                        disabled={isLoading}
                    />
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn-primary submitButton-customizable"
                        aria-label="submit"
                    >
                        {!isLoading ? 'Continue' : 'Please wait...'}
                    </button>
                </form>
            )}
        </Formik>
    </OauthContent>;
};
