import compassPlusLogo from '../../images/compass_plus/logos/compass-plus-logo.svg';
import {Amplify} from 'aws-amplify';
import awsExports from '../../aws-exports';


const Header = () => {
  Amplify.configure(awsExports);
  const cecWebUrl = process.env.REACT_APP_CEC_WEB_URL;

  return (
    <header className="login-header">
      <div className="container">
        <img src={compassPlusLogo} width="300" alt="Compass Plus"/>
        <div className="header-nav">
          <ul className="m-0 p-0">
            <li>
              <a
                href={cecWebUrl + "/about-us/contact-us"}
                className="cec-btn cec-btn-primary-teal link-as-button">
                Contact us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
