import React from 'react';
import { PATH_SCHOOLSHUB } from '../../Router';

const SetupFormLayout = ({ children }) => (
  <div className="row">
    <div className="col-6 m-auto">
      <div className="mb-2">
        <a href={PATH_SCHOOLSHUB} className="link-teal-to-gray font-14">
          Go back to login page
        </a>
      </div>
      <div className="box">
        {children}
      </div>
    </div>
  </div>
);

export default SetupFormLayout;
