import React from 'react';
import cecLogoWhite from '../../../images/cec-logo-white.png';

export const OauthContent = ({descriptionContent, children}) => {
  return (
      <>
        <div className="container">
            <div className="modal-dialog">
                <div className="modal-content background-customizable modal-content-mobile visible-xs visible-sm">
                    <div>
                        <div>
                            <div className="banner-customizable">
                                <center><img alt='logo' className={'logo-customizable'} src={cecLogoWhite} /></center>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div></div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <span className="textDescription-customizable">
                                            {descriptionContent}
                                        </span>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};
