import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import TextInput from '../../components/TextInput';
import ForgottenPasswordModal from '../../components/ForgottenPasswordModal';
import AskMFAModal from '../../components/mfa/modals/AskMFAModal';
import Error from '../../components/Error';
import Layout from '../../components/compass_plus/Layout';
import { PATH_COMPASS_PLUS } from '../../Router';
import { APP_COMPASS_PLUS, MFA_TYPE } from '../../constants';

import handleLogin from "../../components/compass_plus/HandleLogin";
import { sendMFASelectionAnswer } from '../../components/Utils';

const TITLE = 'Compass+ - Log in';
export const COGNITO_CLIENT_METADATA = { app: APP_COMPASS_PLUS };
const PASSWORD_RESET_EMAIL_CLIENT_METADATA = { ...COGNITO_CLIENT_METADATA, action: 'resetPassword' };

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
  password: Yup.string()
    .required('Please enter a password.'),
});

const Login = () => {
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();
  const [ awsErrorMessage, setAwsErrorMessage ] = useState();
  const [ isOpenForgottenPasswordModal, setIsOpenForgottenPasswordModal ] = useState(false);
  const [ forgottenPasswordEmail, setForgottenPasswordEmail ] = useState();
  const [ successMessage, setSuccessMessage ] = useState(null);

  const [ isMfaModalOpen, setMfaModalOpen ] = useState(false);
  const [ cognitoUser, setCognitoUser ] = useState(null);
  const [ userCredentials, setUserCredentials ] = useState([]);

  const localHandleLogin = (forcedCognitoUser = null) => {
    handleLogin({
      cognitoUser: forcedCognitoUser ?? cognitoUser,
      setErrorMessage: setErrorMessage,
      setIsLoggingIn: setIsLoggingIn,
      setMfaModalOpen: setMfaModalOpen,
    });
  };

  const showMFAModal = (values) => {
    setUserCredentials([values.email, values.password]);
    setMfaModalOpen(true);
  }

  const handleSubmit = (values) => {
    setIsLoggingIn(true);
    cleanErrors();

    Auth.signIn(values.email, values.password, COGNITO_CLIENT_METADATA)
      .then((cognito) => {
        const type = cognito.challengeName;

        if ([MFA_TYPE.APP, MFA_TYPE.EMAIL, MFA_TYPE.SMS].includes(type)) {
          setCognitoUser(cognito);

          showMFAModal(values);

          return;
        }

        if ( MFA_TYPE.SELECT === type ) {
          cognito.challengeName = MFA_TYPE.SMS;
          setCognitoUser(cognito);
          sendMFASelectionAnswer(MFA_TYPE.SMS, {
            mfaRequired: (challengeName, challengeParameters) => {
              showMFAModal(values);
            },
            totpRequired: (challengeName, challengeParameters) => {
              showMFAModal(values);
            }
          }, cognito, COGNITO_CLIENT_METADATA);

          return;
        }

        setCognitoUser(cognito);

        localHandleLogin(cognito);
      })
      .catch((error) => {
        setAwsErrorMessage(error.message);
        setIsLoggingIn(false);
      });
  }

  useEffect(() => {
    document.title = TITLE;

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const logoutParam = searchParams.get('logout');

    if (null != logoutParam) {
      window.history.replaceState({}, document.title, PATH_COMPASS_PLUS);

      Auth.signOut()
        .then(() => {
        });

      return;
    }

    const resetParam = searchParams.get('reset');

    if (null != resetParam) {
      window.history.replaceState({}, document.title, PATH_COMPASS_PLUS);

      setIsOpenForgottenPasswordModal(true);
      setForgottenPasswordEmail(resetParam);

      return;
    }

    const resetPasswordSuccessParam = searchParams.get('reset-password-success');
    const phoneNumberSetupParam = searchParams.get('phone-number-setup-success');

    if (null != resetPasswordSuccessParam) {
      setSuccessMessage('Your password has been reset.');
    }

    if (null !== phoneNumberSetupParam) {
      setSuccessMessage('Your MFA has been updated.');
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanAll();
    };
  }, []);

  const cleanAll = () => {
    cleanErrors();

    setIsLoggingIn(false);
  };

  const cleanErrors = () => {
    setAwsErrorMessage(null);
    setErrorMessage(null);
  };

  const cecWebUrl = process.env.REACT_APP_CEC_WEB_URL;

  return (
    <Layout alertMessage={successMessage}>
      {/*Description Col */}
      <div className="col-left col-lg-6 col-12">
        <div className="cover-text">
          <div className="mb-3">
            <h2 className="font-weight-bold mb-3">
              Welcome to Compass+
            </h2>
            <p className="mb-4">
              Compass+ is a new tool from The Careers &amp; Enterprise Company which will
              help you benchmark, manage, track and report on your school's careers
              programme.
            </p>
            <strong>
              Key benefits for Careers Leaders
            </strong>
          </div>
          <div className="features">
            <div className="feature">
              <i className="mdi mdi-timelapse" />
              <p>
                <strong>Save time and plan with ease.</strong> Quickly map out your
                careers programme for the academic year.
              </p>
            </div>
            <div className="feature">
              <i className="mdi mdi-bullseye-arrow" />
              <p>
                <strong>Be more strategic and targeted.</strong> Create custom cohorts
                so you can target relevant careers interventions to the learners most in
                need.
              </p>
            </div>
            <div className="feature">
              <i className="mdi mdi-radar" />
              <p>
                <strong>Measure and monitor effectively.</strong> Create detailed
                reports and data visualisations to monitor performance.
              </p>
            </div>
            <div className="feature">
              <i className="mdi mdi-charity" />
              <p>
                <strong>Collaborate with colleagues.</strong> Encourage collaboration by
                allocating tasks, sharing contacts and allowing colleagues to contribute
                to your careers education programme.
              </p>
            </div>
            <div className="feature">
              <i className="mdi mdi-account-group" />
              <p>
                <strong>Reach and build a wider network.</strong> Receive intelligent
                recommendations for activities and store your careers partners in one
                place.
              </p>
            </div>
          </div>
          <div className="text-center">
            <a
              href={cecWebUrl + "/schools-colleges/compass-plus"}
              className="cec-btn cec-btn-soft-teal link-as-button d-block w-100"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here for more information
            </a>
          </div>
        </div>
      </div>
      {/* Form Col */}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ getFieldProps, errors, handleSubmit }) => (
          <div className="col-right col-lg-6 col-12">
            <div className="cover-form">
              <div className="form-header bg-teal rounded-top shadow-sm p-4">
                <h2 className="text-white">Log in to your account</h2>
              </div>
              <form
                className="border rounded-bottom p-4"
                onSubmit={handleSubmit}
              >
                {/*Email */}
                <div className="default-form text-left">
                  <div className="form-group row">
                    <div className="form-block col-12">
                      <TextInput
                        {...getFieldProps('email')}
                        id="email"
                        label="Your email"
                        placeholder="Email address"
                        autoComplete="email"
                        required
                        error={errors.email}
                      />
                      <i className="mdi mdi-email-outline input-icon" />
                    </div>
                  </div>
                </div>
                {/*Password*/}
                <div className="default-form text-left">
                  <div className="form-group row">
                    <div className="form-block col-12">
                      <TextInput
                        {...getFieldProps('password')}
                        id="password"
                        label="Your password"
                        placeholder="Password"
                        autoComplete="password"
                        required
                        error={errors.password}
                        type="password"
                      />
                      <i className="mdi mdi-key-outline input-icon" />
                    </div>
                  </div>
                </div>
                {/* Forgot - Don't include Remember me checkbox */}
                <div className="form-footer">
                  <div className="forgotten">
                    <button
                      type="button"
                      className="button-as-link link-teal-to-gray font-14"
                      onClick={(event) => {
                        event.preventDefault();

                        setIsOpenForgottenPasswordModal(true);
                      }}
                    >
                      I have forgotten my credentials
                    </button>
                  </div>
                </div>
                {/* Message Errors */}
                <Error
                  message={errorMessage ?? awsErrorMessage}
                  isAws={null == errorMessage && null != awsErrorMessage}
                  resetPasswordOnClick={() => setIsOpenForgottenPasswordModal(true)}
                />
                {/* Login Button */}
                <button
                  type="submit"
                  className="cec-btn cec-btn-primary-teal w-100 mt-3 mp-compass-plus-login-button"
                  disabled={isLoggingIn}
                >
                  {!isLoggingIn ? 'Log in' : 'Logging in...'}
                </button>
                {/* ISO27001 guidance */}
                <p style={{textAlign: "left", marginTop: "1rem"}} className="text-muted">
                  This website is for Authorised Users and is subject to our&nbsp;
                  <a
                    className="link-teal-to-gray"
                    href={cecWebUrl + "/careers-leaders/tools-resources/terms-and-conditions/"}>
                    Terms and Conditions.
                  </a>
                </p>
              </form>
            </div>
            <div>
              <br />
            </div>
            <div className="cover-form">
              <div className="border rounded-bottom p-4">
                <div className="default-form text-left">
                  <strong>Want to learn more?</strong>
                  <div className="form-group row">
                    <p>Sign up to a training webinar and learn everything there is to know about Compass+</p>
                  </div>
                </div>
                <a
                  href="https://careersandenterprise.zendesk.com/hc/en-gb/articles/4404658141074-Webinars-sign-up-for-a-Compass-training-webinar"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cec-btn cec-btn-soft-teal link-as-button d-block w-100"
                >
                  Sign up
                </a>
              </div>
            </div>
          </div>
        )}
      </Formik>

      <ForgottenPasswordModal
        email={forgottenPasswordEmail}
        isOpen={isOpenForgottenPasswordModal}
        toggle={() => setIsOpenForgottenPasswordModal((prev) => !prev)}
        cognitoClientMetadata={PASSWORD_RESET_EMAIL_CLIENT_METADATA}
      />
      {isMfaModalOpen && (
        <AskMFAModal
          project={'Compass Plus'}
          toggle={() => setMfaModalOpen((prev) => !prev)}
          setCognitoUser={setCognitoUser}
          cognitoUser={cognitoUser}
          userCredentials={userCredentials}
          handleSuccess={() => localHandleLogin()}
          handleLoginAwsError={(errorMessage) => {
            setAwsErrorMessage(errorMessage);
            setIsLoggingIn(false);
            setMfaModalOpen(false);
          }}
          clientMetadata={COGNITO_CLIENT_METADATA}
        />
      )}
    </Layout>
  );
};

export default Login;
