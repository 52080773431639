import * as Yup from 'yup';

export const APP_COMPASS_PLUS = 'compassplus';
export const APP_EAN_REGISTER = 'eanr';
export const APP_CLTP = 'cltp';
export const APP_SCHOOLSHUB = 'schoolshub';

export const DEFAULT_ERROR = 'Unexpected error. Please try again.';
export const LOGIN_ERROR_UNAUTHORIZED = 'Unauthorized.';
export const AWS_ERROR_INCORRECT_CREDENTIALS = 'Incorrect username or password.';
export const AWS_ERROR_DISABLED_USER = 'User is disabled.';
export const AWS_ERROR_USER_DOES_NOT_EXIST = 'User does not exist.';
export const AWS_ERROR_PASSWORD_EXPIRED = 'PostAuthentication failed with error Password expired.';
export const AWS_ERROR_SESSION_EXPIRED = 'Invalid session for the user, session is expired.';
export const AWS_ERROR_RESET_REQUIRED = 'Password reset required for the user';
export const AWS_ERROR_USER_NOT_FOUND = 'Username/client id combination not found.';
export const AWS_ERROR_INVALID_CODE = 'Invalid verification code provided, please try again.';
export const AWS_ERROR_EXPIRED_CODE = 'Invalid code provided, please request a code again.';

export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const E164_REGEX = /^\+[1-9]\d{10,14}$/;

export const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
  password: Yup.string()
    .required('Please enter the password.')
    .min(6, 'Password needs to be 10 characters.')
    .max(99, 'Password needs to be fewer than 99 characters.')
    .matches(/.*[A-Z]+.*/, 'Password must include a capital letter.')
    .matches(/.*[a-z]+.*/, 'Password must include a lowercase letter.')
    .matches(/.*[0-9]+.*/, 'Password must include a number.')
    .matches(/.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]+.*/, 'Password must include a special character.'),
  repeatPassword: Yup.string()
    .required('Please repeat the password.')
    .oneOf([ Yup.ref('password'), '' ], 'Passwords don\'t match, please try again.'),
  code: Yup.string()
    .required('Please enter a valid verification code.')
    .matches(/^[0-9]{6}$/, 'Please enter a valid verification code.'),
});

export const PHONE_NUMBER_SETUP_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
  phoneNumber: Yup.string()
    .required('Please enter a phone number.')
    .test('edge-case', "We can not process this phone number. Please contact us.", (value) => {
      if (!value) {
        return true;
      }

      return !(value.length > 4 && value.slice(-4) === '0000');
    })
    .matches(E164_REGEX, "Your phone number should follow the international standard."),
  repeatPhoneNumber: Yup.string()
    .required('Please repeat your phone number.')
    .oneOf([ Yup.ref('phoneNumber'), '' ], 'Phone numbers don\'t match, please try again.'),
});

export const MFA_TYPE = {
  EMAIL: 'EMAIL_MFA',
  APP: 'SOFTWARE_TOKEN_MFA',
  SMS: 'SMS_MFA',
  SELECT: 'SELECT_MFA_TYPE',
};

export const MFA_VALIDATION_SCHEMA = Yup.object().shape({
  code: Yup.string()
    .matches(/^\d+$/, 'The code should have digits only.')
    .min(6, 'The code is too short.')
    .max(6, 'The code is too long.')
    .required('Please enter your code.'),
});
