import React from 'react';
import {PATH_EAN_REGISTER} from "../../Router";

import cecLogo from "../../images/cec-logo.svg";

const SetupFormLayout = ({children}) => (
  <>
    <div className="ean-login-text">
      <img src={cecLogo} alt="The Careers & Enterprise Company" title="The Careers & Enterprise Company" />
    </div>
    <div className="mb-2">
      <a href={PATH_EAN_REGISTER} className="link-teal-to-gray font-14">
        Go back to login page
      </a>
    </div>
    <div className="ean-login-form">
      {children}
    </div>
  </>
);

export default SetupFormLayout;
