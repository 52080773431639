import {Auth} from 'aws-amplify';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
    AWS_ERROR_DISABLED_USER,
    AWS_ERROR_EXPIRED_CODE,
    AWS_ERROR_INVALID_CODE,
    AWS_ERROR_USER_DOES_NOT_EXIST,
    FAILURE,
    LOADING,
    RESET_PASSWORD_VALIDATION_SCHEMA,
} from '../constants';
import TextInput from './TextInput';
import {ErrorMessage, InfoMessage, SubmitButton} from "./Forms";

const awsError = (awsErrorMessage) => {
    switch (awsErrorMessage) {
        case AWS_ERROR_DISABLED_USER:
            return (
                <>
                    Your account needs to be validated before you can reset your password. Please contact the
                    Administrator for your school or college, or
                    <a href="mailto:compassplus@careersandenterprise.atlassian.net"
                       className="link-red-to-gray">
                        contact us
                    </a> if you do not know your Administrator.
                </>
            );
        case AWS_ERROR_USER_DOES_NOT_EXIST:
            return (
                <>
                    Your email is not registered. Please contact the Administrator for your school or college, or
                    <a href="mailto:compassplus@careersandenterprise.atlassian.net"
                       className="link-red-to-gray">
                        contact us
                    </a> if you do not know your Administrator.
                </>
            );
        case AWS_ERROR_INVALID_CODE:
            return "We're sorry, the code you have entered is either incorrect or invalid because it has been" +
                " replaced by a newer code.  If you have requested more than one reset email, please make sure that" +
                " you are entering the code from the most recent email.";
        case AWS_ERROR_EXPIRED_CODE:
            return "We're sorry. It seems the code you are entering has expired after 60 minutes." +
                " Please request a new password reset.";
        default:
            return awsErrorMessage;
    }
};

const ResetPasswordForm = ({title, path}) => {
    const {code} = useParams();
    const history = useHistory();
    const [callState, setCallState] = useState(null);
    const [awsErrorMessage, setAwsErrorMessage] = useState();

    useEffect(() => {
        document.title = title;

        return () => {
            cleanAll();
        };
    }, []);

    const cleanAll = () => {
        cleanErrors();

        setCallState(null);
    };

    const cleanErrors = () => {
        setAwsErrorMessage(null);
    };

    return (
        <Formik
            validationSchema={RESET_PASSWORD_VALIDATION_SCHEMA}
            initialValues={{
                email: '',
                password: '',
                repeatPassword: '',
                code: code ?? '',
            }}
            onSubmit={(values) => {
                cleanErrors();
                setCallState(LOADING);

                Auth.forgotPasswordSubmit(values.email, values.code, values.password)
                    .then(() => {
                        history.push(path);
                    })
                    .catch((error) => {
                        setCallState(FAILURE);
                        setAwsErrorMessage(error.message);
                    });
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({getFieldProps, errors, handleSubmit, initialValues}) => (
                <form onSubmit={handleSubmit}>
                    <div className="default-form text-left">
                        <div className="form-group row">
                            <div className="form-block col-12">
                                <TextInput
                                    {...getFieldProps('email')}
                                    id="email"
                                    label="Confirm your email address"
                                    placeholder="Email address"
                                    autoComplete="email"
                                    required
                                    error={errors.email}
                                />
                                <i className="mdi mdi-email-outline input-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="default-form text-left">
                        <div className="form-group row">
                            <div className="form-block col-12">
                                <TextInput
                                    {...getFieldProps('password')}
                                    id="password"
                                    label="Enter your new password"
                                    placeholder="Password"
                                    help="Your password must be 10 characters and include numbers, punctuation marks, capital letters and lowercase letters."
                                    error={errors.password}
                                    type="password"
                                    required
                                />
                                <i className="mdi mdi-lock-outline input-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="default-form text-left">
                        <div className="form-group row">
                            <div className="form-block col-12">
                                <TextInput
                                    {...getFieldProps('repeatPassword')}
                                    id="repeat-password"
                                    label="Enter your password one more time"
                                    placeholder="Repeat password"
                                    error={errors.repeatPassword}
                                    type="password"
                                    required
                                />
                                <i className="mdi mdi-lock-outline input-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="default-form text-left">
                        <div className="form-group row">
                            <div className="form-block col-12">
                                <TextInput
                                    {...getFieldProps('code')}
                                    id="code"
                                    label="Verification Code"
                                    placeholder="Code"
                                    help="You should have received this via email"
                                    error={errors.code}
                                    required
                                    disabled={null != initialValues.code && '' !== initialValues.code}
                                />
                                <i className="mdi mdi-lock-outline input-icon"/>
                            </div>
                        </div>
                    </div>
                    <InfoMessage>
                        We will always take appropriate measures to keep your personal information confidential, secure,
                        and protected, including when we need to share it with third parties.
                    </InfoMessage>
                    {awsErrorMessage && (
                        <ErrorMessage>
                            {awsError(awsErrorMessage)}
                        </ErrorMessage>
                    )}
                    <SubmitButton
                        disabled={LOADING === callState}
                    >
                        {LOADING !== callState ? 'Reset password' : 'Resetting...'}
                    </SubmitButton>
                </form>
            )}
        </Formik>
    );
};

export default ResetPasswordForm;
