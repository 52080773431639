import React from 'react';
import TextInput from '../../../components/TextInput';

const ImpersonateTextInput = ({
  idAndName,
  labelAndPlaceholder,
  value,
  type = 'text',
  ...restProps
}) => {
  return (
    <TextInput
      id={idAndName}
      type={type}
      label={labelAndPlaceholder}
      name={idAndName}
      required
      className="form-control inputField-customizable w-100"
      value={value}
      labelClass="label-customizable"
      placeholder={labelAndPlaceholder}
      {...restProps}
    />
  );
};

export default ImpersonateTextInput;
