import React from 'react';
import Layout from "../../components/compass_plus/Layout";
import SetupFormLayout from "../../components/compass_plus/SetupFormLayout";
import {PATH_COMPASS_PLUS_RESET_PASSWORD_SUCCESS} from '../../Router';
import ResetPasswordForm from "../../components/ResetPasswordForm";

const TITLE = 'Compass+ - Reset password';

const ResetPassword = () => {
    return (
        <Layout>
            <SetupFormLayout title="Reset password">
                <ResetPasswordForm
                    title={TITLE}
                    path={PATH_COMPASS_PLUS_RESET_PASSWORD_SUCCESS}
                />
            </SetupFormLayout>
        </Layout>
    );
};

export default ResetPassword;
