import React from 'react';

export const SubmitButton = ({ children, isSchoolshub, ...other }) => (
  <button
    type="submit"
    className={`w-100 mt-3 ${!isSchoolshub ? 'cec-btn cec-btn-primary-teal' : 'btn btn-grey'}`}
    {...other}
  >
    {children}
  </button>
);

export const InfoMessage = ({ children }) => (
  <div className="form-footer text-left">
    {children}
  </div>
);

export const ErrorMessage = ({ children }) => (
  <div className="alert alert-cec alert-cec-warning text-left fade-in-bottom">
    {children}
  </div>
);
