import React from 'react';
import {PATH_COMPASS_PLUS} from "../../Router";

const SetupFormLayout = ({ title, children }) => (
  <div className="w-100">
    <div className="mb-2">
      <a href={PATH_COMPASS_PLUS} className="link-teal-to-gray font-14">
        Go back to login page
      </a>
    </div>
    <div className="col-lg-8 col-12 mx-auto">
      <div className="cover-form">
        <div className="form-header bg-teal rounded-top shadow-sm p-4">
          <h2 className="text-white">{title}</h2>
        </div>
        <div className="border rounded-bottom p-4">
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default SetupFormLayout;
