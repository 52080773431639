import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { Amplify, Auth } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { OauthContent } from '../digitalhub/components/OauthContent';
import ImpersonateTextInput from './components/ImpersonateTextInput';
import Error from '../../components/Error';
import handleLogin from '../../components/compass_plus/HandleLogin';
import { MFA_TYPE } from '../../constants';
import AskMFAModal from '../../components/mfa/modals/AskMFAModal';
import { Formik } from 'formik';
import { COGNITO_CLIENT_METADATA as COMPASS_PLUS_COGNITO_CLIENT_METADATA } from '../compass_plus/Login';
import * as Yup from 'yup';
import { sendMFASelectionAnswer } from '../../components/Utils';

const TITLE = 'Impersonate user';

const yupEmail = Yup.string()
  .email('Please enter a valid email.')
  .required('Please enter an email.');
const VALIDATION_SCHEMA = Yup.object().shape({
  email: yupEmail,
  emailToImpersonate: yupEmail,
  password: Yup.string()
    .required('Please enter a password.'),
});

// To locate and remove the unsafe symbols & < > " ' `
const REGEX_SANITISE = /[&<>"'`]/g;

Amplify.configure(awsExports);

export default function Impersonate() {
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { email, emailToImpersonate } = useParams();

  const decodeAndSanitise = (email) => {
    return decodeURIComponent(email).replace(REGEX_SANITISE, '');
  }

  const [ isMfaModalOpen, setMfaModalOpen ] = useState(false);
  const [ cognitoUser, setCognitoUser ] = useState(null);
  const [ awsErrorMessage, setAwsErrorMessage ] = useState();
  const [ impersonationData, setImpersonationData ] = useState(null);

  useEffect(() => {
    document.title = TITLE;
  }, []);

  const localHandleLogin = (forcedCognitoUser = null) => {
    handleLogin({
      cognitoUser: forcedCognitoUser ?? cognitoUser,
      setErrorMessage: setErrorMessage,
      setIsLoggingIn: setIsLoggingIn,
      setMfaModalOpen: setMfaModalOpen,
    });
  };

  const showMFAModal = (values) => {
    setImpersonationData({ email: values.email, emailToImpersonate: values.emailToImpersonate, password: values.password });
    setMfaModalOpen(true);
  }
  
  const handleSubmit = (values) => {
    setIsLoggingIn(true);
    setErrorMessage(null);

    Auth.signIn(values.email, values.password, { ...COMPASS_PLUS_COGNITO_CLIENT_METADATA, userToImpersonate: values.emailToImpersonate })
      .then((cognito) => {
        const type = cognito.challengeName;

        if ([ MFA_TYPE.APP, MFA_TYPE.EMAIL, MFA_TYPE.SMS ].includes(type)) {
          setCognitoUser(cognito);

          showMFAModal(values);

          return;
        }

        if ( MFA_TYPE.SELECT === type ) {
          cognito.challengeName = MFA_TYPE.SMS;
          setCognitoUser(cognito);
          sendMFASelectionAnswer(MFA_TYPE.SMS, {
            mfaRequired: (challengeName, challengeParameters) => {
              showMFAModal(values);
            },
            totpRequired: (challengeName, challengeParameters) => {
              showMFAModal(values);
            }
          }, cognito, COMPASS_PLUS_COGNITO_CLIENT_METADATA);

          return;
        }

        setCognitoUser(cognito);

        localHandleLogin(cognito);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsLoggingIn(false);
      })
    ;
  };

  return (
    <>
      {isMfaModalOpen && (
        <AskMFAModal
          project={'Compass Plus'}
          toggle={() => setMfaModalOpen((prev) => !prev)}
          setCognitoUser={setCognitoUser}
          cognitoUser={cognitoUser}
          userCredentials={[ impersonationData.email, impersonationData.password ]}
          handleSuccess={(cognitoUser) => localHandleLogin(cognitoUser)}
          handleLoginAwsError={(errorMessage) => {
            setAwsErrorMessage(errorMessage);
            setIsLoggingIn(false);
            setMfaModalOpen(false);
          }}
          clientMetadata={{ ...COMPASS_PLUS_COGNITO_CLIENT_METADATA, userToImpersonate: impersonationData.emailToImpersonate }}
        />
      )}

      <OauthContent
        descriptionContent={'Please introduce your password to proceed with the impersonation.'}
      >
        <Formik
          initialValues={{
            email: decodeAndSanitise(email),
            emailToImpersonate: decodeAndSanitise(emailToImpersonate),
            password: '',
          }}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
          {({ getFieldProps, handleSubmit, errors }) => (
            <form
              onSubmit={handleSubmit}
            >
              <ImpersonateTextInput
                {...getFieldProps('emailToImpersonate')}
                idAndName="emailToImpersonate"
                labelAndPlaceholder="Email of user to impersonate"
                error={errors.emailToImpersonate}
                disabled
                required
              />
              <ImpersonateTextInput
                {...getFieldProps('email')}
                idAndName="email"
                labelAndPlaceholder="Email"
                error={errors.email}
                disabled
                required
              />
              <ImpersonateTextInput
                {...getFieldProps('password')}
                idAndName="password"
                labelAndPlaceholder="Password"
                error={errors.password}
                type="password"
                required
              />
              {/* Message Errors */}
              <Error
                message={errorMessage ?? awsErrorMessage}
                isAws={null == errorMessage && null != awsErrorMessage}
                resetPasswordOnClick={() => console.log(true)}
              />
              <button
                type="submit"
                disabled={isLoggingIn}
                className="btn btn-primary submitButton-customizable"
              >
                {!isLoggingIn ? 'Continue' : 'Please wait...'}
              </button>
            </form>
          )}
        </Formik>
      </OauthContent>
    </>
  );
}
