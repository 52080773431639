import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <div className="schoolshub-login-page">
    {/* Header */}
    <Header />
    {/* Content */}
    <div className="container">
      {/* Page Content */}
      {children}
    </div>
    {/* Footer */}
    <Footer />
  </div>
);

export default Layout;
