import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import backGround from '../../images/compass_plus/bg.png';

const Layout = ({ alertMessage = null, children }) => (
  <React.Fragment>
    <div className="cover-image" style={{ backgroundImage: `url(${backGround})` }} />
    <div id="content-login">
      <Header />
      <div className="login-main">
        <div className="login-cover container px-0">
          {alertMessage && (
            <div className="alert alert-success mb-5">
              {alertMessage}
            </div>
          )}
          <div className="row m-0 p-0">
            {children}
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </React.Fragment>
)

export default Layout;
