import React from 'react';
import Header from '../../components/schoolshub/Header';
import Footer from '../../components/schoolshub/Footer';
import {PATH_SCHOOLSHUB_RESET_PASSWORD_SUCCESS} from '../../Router';
import ResetPasswordForm from "../../components/ResetPasswordForm";

const TITLE = 'Schools Hub - Reset password';

const ResetPassword = () => {
    return (
        <div id="app">
            <Header/>
            <div className="container">
                <ResetPasswordForm
                    title={TITLE}
                    path={PATH_SCHOOLSHUB_RESET_PASSWORD_SUCCESS}
                />
            </div>
            <Footer/>
        </div>
    );
};

export default ResetPassword;
